import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import Navbar from "@/components/layout/Navbar";
import Footer from "@/components/layout/Footer";
import Index from "./pages/Index";
import SearchResults from "./pages/SearchResults";
import ProfileDetail from "./pages/ProfileDetail";
import NotFound from "./pages/NotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import Faq from "./pages/Faq";
import Blog from "./pages/Blog";
import { Routes, Route } from "react-router-dom";

// Main Category Pages
import EscortsIndex from "./pages/escorts/EscortsIndex";
import DominatrixIndex from "./pages/dominatrix/DominatrixIndex";
import MistressIndex from "./pages/mistress/MistressIndex";
import MassageIndex from "./pages/massage/MassageIndex";
import BdsmIndex from "./pages/bdsm/BdsmIndex";

// Escort Pages
import KoreanEscortsLondon from "./pages/escorts/KoreanEscortsLondon";
import ChineseEscortsLondon from "./pages/escorts/ChineseEscortsLondon";
import LutonEscorts from "./pages/escorts/LutonEscorts";
import MiltonKeynesEscorts from "./pages/escorts/MiltonKeynesEscorts";
import DoncasterEscorts from "./pages/escorts/DoncasterEscorts";
import StokeOnTrentEscorts from "./pages/escorts/StokeOnTrentEscorts";
import NewcastleUponTyneEscorts from "./pages/escorts/NewcastleUponTyneEscorts";

// Dominatrix Pages
import NorthwestDominatrix from "./pages/dominatrix/NorthwestDominatrix";
import WalesDominatrix from "./pages/dominatrix/WalesDominatrix";
import KentDominatrix from "./pages/dominatrix/KentDominatrix";
import SussexDominatrix from "./pages/dominatrix/SussexDominatrix";
import LeicesterDominatrix from "./pages/dominatrix/LeicesterDominatrix";
import NorthamptonDominatrix from "./pages/dominatrix/NorthamptonDominatrix";

// Mistress Pages
import ChesterMistress from "./pages/mistress/ChesterMistress";

// BDSM Pages
import SwanseaBdsm from "./pages/bdsm/SwanseaBdsm";

// Massage Pages
import CardiffHappyEnding from "./pages/massage/CardiffHappyEnding";

const queryClient = new QueryClient();

// Handle GitHub Pages SPA routing
const handleGitHubPagesRedirect = () => {
  // Check if we need to redirect
  const redirect = sessionStorage.redirect;
  delete sessionStorage.redirect;
  
  // If we need to redirect, and we're not at the target location
  if (redirect && redirect !== window.location.href) {
    console.log("Handling redirect from:", redirect);
    // Get the pathname from the redirect URL
    const url = new URL(redirect);
    const pathname = url.pathname;
    
    // Push to history without reloading the page
    window.history.replaceState(null, '', pathname);
  }
};

const App = () => {
  const [loaded, setLoaded] = useState(false);
  
  useEffect(() => {
    // Handle GitHub Pages SPA routing
    handleGitHubPagesRedirect();
    
    // Add logging to confirm the application is initializing
    console.log("App component mounted");
    
    // Add a small delay to ensure smooth initial load animation
    setTimeout(() => {
      setLoaded(true);
      console.log("App loaded state set to true");
    }, 300);
  }, []);
  
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <TooltipProvider>
          <Toaster />
          <Sonner />
          <BrowserRouter>
            <div className={`min-h-screen flex flex-col ${loaded ? 'animate-fade-in' : 'opacity-0'}`}>
              <Navbar />
              <main className="flex-1">
                <Routes>
                  <Route path="/" element={<Index />} />
                  <Route path="/search" element={<SearchResults />} />
                  <Route path="/profile/:id" element={<ProfileDetail />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/terms" element={<TermsOfService />} />
                  <Route path="/faq" element={<Faq />} />
                  <Route path="/blog" element={<Blog />} />

                  {/* Main Category Routes */}
                  <Route path="/escorts" element={<EscortsIndex />} />
                  <Route path="/dominatrix" element={<DominatrixIndex />} />
                  <Route path="/mistress" element={<MistressIndex />} />
                  <Route path="/massage" element={<MassageIndex />} />
                  <Route path="/bdsm" element={<BdsmIndex />} />

                  {/* Escort Routes */}
                  <Route path="/escorts/london/korean" element={<KoreanEscortsLondon />} />
                  <Route path="/escorts/london/chinese" element={<ChineseEscortsLondon />} />
                  <Route path="/escorts/luton" element={<LutonEscorts />} />
                  <Route path="/escorts/milton-keynes" element={<MiltonKeynesEscorts />} />
                  <Route path="/escorts/doncaster" element={<DoncasterEscorts />} />
                  <Route path="/escorts/stoke-on-trent" element={<StokeOnTrentEscorts />} />
                  <Route path="/escorts/newcastle-upon-tyne" element={<NewcastleUponTyneEscorts />} />

                  {/* Dominatrix Routes */}
                  <Route path="/dominatrix/northwest" element={<NorthwestDominatrix />} />
                  <Route path="/dominatrix/wales" element={<WalesDominatrix />} />
                  <Route path="/dominatrix/kent" element={<KentDominatrix />} />
                  <Route path="/dominatrix/sussex" element={<SussexDominatrix />} />
                  <Route path="/dominatrix/leicester" element={<LeicesterDominatrix />} />
                  <Route path="/dominatrix/northampton" element={<NorthamptonDominatrix />} />

                  {/* Mistress Routes */}
                  <Route path="/mistress/chester" element={<ChesterMistress />} />

                  {/* BDSM Routes */}
                  <Route path="/bdsm/swansea" element={<SwanseaBdsm />} />

                  {/* Massage Routes */}
                  <Route path="/massage/cardiff/happy-ending" element={<CardiffHappyEnding />} />

                  <Route path="*" element={<NotFound />} />
                </Routes>
              </main>
              <Footer />
            </div>
          </BrowserRouter>
        </TooltipProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
};

export default App;
