
import type { Profile } from "../types/profile";

// Simple implementation that returns a static profile without external dependencies
export const fetchProfileDetails = ({ userId }: { userId: number }): Promise<Profile> => {
  // Return a promise that resolves with a hardcoded profile
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        userID: userId,
        nickname: "Bella Rose",
        gender: "female",
        age: 25,
        location: {
          country: "United Kingdom",
          region: "London",
          city: "Central London"
        },
        summary: "Hello, I'm Bella! I'm a fun-loving and adventurous companion based in Central London. I enjoy meeting new people and creating unforgettable experiences. I'm available for both incalls and outcalls in the London area.",
        images: [
          {
            url: "/placeholder.svg",
            isMain: true,
            isVerified: true
          },
          {
            url: "/placeholder.svg",
            isMain: false,
            isVerified: true
          },
          {
            url: "/placeholder.svg",
            isMain: false,
            isVerified: false
          }
        ],
        services: [
          "Dinner Dates",
          "Overnight",
          "Weekend Getaways",
          "Travel Companion"
        ],
        rates: {
          incall: {
            hourly: 300,
            twoHours: 500,
            overnight: 1200
          },
          outcall: {
            hourly: 350,
            twoHours: 600,
            overnight: 1500
          }
        },
        measurements: {
          height: "5'7\" (170cm)",
          bust: "34C",
          dress: "Size 8 (UK)"
        },
        availability: {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: false,
          twentyFourHours: false
        },
        lastActive: new Date().toISOString(),
        verificationBadge: true,
        rating: {
          overall: 4.9,
          count: 48
        },
        contactInfo: {
          phone: "+1234567890",
          email: "contact@example.com"
        }
      });
    }, 500); // Simulate network delay
  });
};
