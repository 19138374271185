
import { useState } from "react";
import { ChevronLeft, ChevronRight, ExpandIcon } from "lucide-react";
import { ProfileImage } from "@/types/profile";
import { cn } from "@/lib/utils";

interface ProfileGalleryProps {
  images: ProfileImage[];
}

const ProfileGallery = ({ images }: ProfileGalleryProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState<Record<number, boolean>>({});
  
  const handlePrev = () => {
    setActiveIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };
  
  const handleNext = () => {
    setActiveIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };
  
  const handleImageLoad = (index: number) => {
    setImagesLoaded((prev) => ({ ...prev, [index]: true }));
  };
  
  return (
    <div className="relative">
      <div className="relative overflow-hidden rounded-xl aspect-[4/3] md:aspect-[16/9] glass-card">
        {/* Image skeleton */}
        <div 
          className={cn(
            "absolute inset-0 bg-muted/20 animate-pulse-slow z-0",
            imagesLoaded[activeIndex] ? "opacity-0" : "opacity-100"
          )} 
        />
        
        <img
          src={images[activeIndex]?.url || "/placeholder.svg"}
          alt={`Gallery image ${activeIndex + 1}`}
          className={cn(
            "w-full h-full object-contain transition-opacity duration-300",
            imagesLoaded[activeIndex] ? "opacity-100" : "opacity-0"
          )}
          onLoad={() => handleImageLoad(activeIndex)}
        />
        
        <button
          onClick={() => setLightboxOpen(true)}
          className="absolute bottom-4 right-4 bg-background/80 backdrop-blur-sm text-white p-2 rounded-full hover:bg-background transition-colors"
          aria-label="Expand image"
        >
          <ExpandIcon size={20} />
        </button>
      </div>
      
      {/* Navigation buttons */}
      <button
        onClick={handlePrev}
        className="absolute left-4 top-1/2 -translate-y-1/2 bg-background/80 backdrop-blur-sm text-white p-2 rounded-full hover:bg-background transition-colors"
        aria-label="Previous image"
      >
        <ChevronLeft size={24} />
      </button>
      
      <button
        onClick={handleNext}
        className="absolute right-4 top-1/2 -translate-y-1/2 bg-background/80 backdrop-blur-sm text-white p-2 rounded-full hover:bg-background transition-colors"
        aria-label="Next image"
      >
        <ChevronRight size={24} />
      </button>
      
      {/* Thumbnails */}
      <div className="mt-4 grid grid-cols-5 gap-2">
        {images.map((image, index) => (
          <button
            key={index}
            onClick={() => setActiveIndex(index)}
            className={cn(
              "rounded-lg overflow-hidden aspect-square transition-opacity",
              index === activeIndex ? "ring-2 ring-primary" : "opacity-60 hover:opacity-100"
            )}
          >
            <img
              src={image.url}
              alt={`Thumbnail ${index + 1}`}
              className="w-full h-full object-cover"
            />
          </button>
        ))}
      </div>
      
      {/* Lightbox */}
      {lightboxOpen && (
        <div className="fixed inset-0 bg-black/90 z-50 flex items-center justify-center animate-fade-in" onClick={() => setLightboxOpen(false)}>
          <div className="max-w-5xl max-h-[90vh] px-4">
            <img
              src={images[activeIndex]?.url}
              alt={`Gallery image ${activeIndex + 1}`}
              className="max-w-full max-h-[90vh] object-contain"
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          
          <button
            onClick={(e) => {
              e.stopPropagation();
              handlePrev();
            }}
            className="absolute left-4 top-1/2 -translate-y-1/2 bg-background/60 backdrop-blur-sm text-white p-3 rounded-full hover:bg-background/80 transition-colors"
            aria-label="Previous image"
          >
            <ChevronLeft size={28} />
          </button>
          
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleNext();
            }}
            className="absolute right-4 top-1/2 -translate-y-1/2 bg-background/60 backdrop-blur-sm text-white p-3 rounded-full hover:bg-background/80 transition-colors"
            aria-label="Next image"
          >
            <ChevronRight size={28} />
          </button>
          
          <button
            onClick={() => setLightboxOpen(false)}
            className="absolute top-6 right-6 text-white"
            aria-label="Close lightbox"
          >
            <span className="text-3xl">&times;</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default ProfileGallery;
