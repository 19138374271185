
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const TermsOfService = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <div className="pt-16 sm:pt-20 md:pt-24 min-h-screen bg-background">
      <Helmet>
        <title>Terms of Service | CAMBIRDS - Adult Webcams & Escort Directory</title>
        <meta name="description" content="Terms of Service for CAMBIRDS, your trusted source for adult webcams, live cams, and escort services information. Read our terms before using our platform." />
        <meta name="robots" content="noindex, follow" />
        <link rel="canonical" href="https://cambirds.com/terms" />
      </Helmet>
      
      <div className="container mx-auto px-4 max-w-[1000px]">
        <div className="mb-8 text-center">
          <h1 className="text-2xl sm:text-3xl font-bold text-white mb-4">
            Terms of Service
          </h1>
          <p className="text-muted-foreground">
            Last Updated: {new Date().toLocaleDateString()}
          </p>
        </div>
        
        <div className="glass-card p-6 sm:p-8 rounded-lg mb-8">
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">1. Introduction</h2>
            <p className="text-muted-foreground mb-4">
              Welcome to CAMBIRDS ("we," "us," or "our"). It is important to us that you and other visitors 
              have the best experience while using our website, and that you understand your legal rights and 
              obligations. This agreement governs your use of our website, including any content, 
              functionality, and services offered.
            </p>
            <p className="text-muted-foreground mb-4">
              CAMBIRDS operates as an affiliate partner for AdultWork. By accessing our website, you may 
              be exposed to adult-oriented content including graphic visual depictions, nudity, adult language, 
              and descriptions of explicit sexual activity.
            </p>
            <p className="text-muted-foreground">
              By using this website, you agree to these Terms of Service. If you do not want to agree to 
              these terms, you must leave the website.
            </p>
          </section>
          
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">2. Adult-Oriented Content; Eligibility</h2>
            <p className="text-muted-foreground mb-4">
              Our website contains links to adult content that is unsuitable for minors. Only individuals 
              (1) who are at least 18-years old and (2) who have reached the age of majority where they live 
              may access our website. If you do not meet these age requirements, you must not access our website 
              and must leave now.
            </p>
            <p className="text-muted-foreground mb-4">
              By accessing our website, you state that the following facts are accurate:
            </p>
            <ul className="list-disc pl-6 text-muted-foreground space-y-2 mb-4">
              <li>You are at least 18-years old, have reached the age of majority where you live, and you have the legal capacity to enter into this agreement</li>
              <li>You are aware of the adult nature of the content you may access through our links</li>
              <li>You are familiar with your community's laws affecting your right to access adult-oriented materials</li>
              <li>You have the legal right to access adult-oriented materials</li>
              <li>You are voluntarily requesting adult-oriented materials for your private enjoyment</li>
              <li>You are not accessing our website from a place, country, or location in which doing so would or could be considered a violation of applicable law</li>
              <li>You will not share our website with a minor or otherwise make it available to a minor</li>
            </ul>
          </section>
          
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">3. Third-Party Services</h2>
            <p className="text-muted-foreground mb-4">
              Our website primarily exists to connect you with AdultWork services. When you click on affiliate links 
              to AdultWork or other third-party websites, you will be subject to their terms of service and privacy 
              policies. We are not responsible for the content, policies, or practices of any third-party website.
            </p>
            <p className="text-muted-foreground">
              We make no representations or warranties about the quality, accuracy, reliability, suitability, or 
              availability of any services offered by third parties that you may access through our website.
            </p>
          </section>
          
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">4. Intellectual Property Rights</h2>
            <p className="text-muted-foreground mb-4">
              The website and its entire contents, features, and functionality (including but not limited to all 
              information, software, text, displays, images, video, and audio, and the design, selection, and 
              arrangement thereof) are owned by us, our licensors, or other providers of such material and are 
              protected by copyright, trademark, patent, trade secret, and other intellectual property or 
              proprietary rights laws.
            </p>
            <p className="text-muted-foreground">
              You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly 
              perform, republish, download, store, or transmit any of the material on our website.
            </p>
          </section>
          
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">5. Disclaimer of Warranties</h2>
            <p className="text-muted-foreground mb-4">
              You understand that we cannot and do not guarantee or warrant that files available for downloading 
              from the internet or the website will be free of viruses or other destructive code. You are responsible 
              for implementing sufficient procedures and checkpoints to satisfy your particular requirements for 
              anti-virus protection and accuracy of data input and output, and for maintaining a means external to 
              our site for any reconstruction of any lost data.
            </p>
            <p className="text-muted-foreground">
              TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A 
              DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY 
              INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR 
              USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF 
              ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
            </p>
          </section>
          
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">6. Limitation of Liability</h2>
            <p className="text-muted-foreground">
              TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL WE, OUR AFFILIATES, OR THEIR LICENSORS, 
              SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, 
              UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE 
              WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING 
              ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT 
              LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF 
              PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, 
              AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF 
              FORESEEABLE.
            </p>
          </section>
          
          <section>
            <h2 className="text-xl font-semibold text-white mb-4">7. Contact Us</h2>
            <p className="text-muted-foreground">
              If you have any questions about these Terms of Service, please contact us at:
              <Link to="/contact" className="text-primary hover:underline ml-1">
                terms@cambirds.com
              </Link>
            </p>
          </section>
        </div>
        
        <div className="text-center mb-8">
          <p className="text-muted-foreground text-sm">
            © {currentYear} CAMBIRDS. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
