
import React from 'react';
import { Helmet } from 'react-helmet-async';
import SearchEngineSchema from '@/components/seo/SearchEngineSchema';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Link } from 'react-router-dom';

const Faq = () => {
  const faqItems = [
    {
      question: "What types of adult webcams can I find through CAMBIRDS?",
      answer: "Through CAMBIRDS, you can discover various types of adult webcams including free adult cams, live sex cams, couple cams, and more. We provide access to platforms similar to Chaturbate, MyFreeCams, and ImLive with models of all categories including asian cams, latina cams, bbw cams, and mature cams."
    },
    {
      question: "Are the webcam models on your partner sites verified?",
      answer: "Yes, all webcam models on our partner platforms are verified to ensure you're connecting with real performers. Our partner sites have strict verification processes to maintain high standards and provide a safe adult entertainment environment."
    },
    {
      question: "What escort services are available through your platform?",
      answer: "Our platform provides information about verified escort services across the UK, including London escorts (Korean escorts London, Chinese escorts London), Cardiff services, and more. We also feature information about dominatrix services in various regions like Northwest dominatrix, Wales dominatrix, and Kent dominatrix providers."
    },
    {
      question: "How do cam-to-cam features work on adult webcam sites?",
      answer: "Cam-to-cam (cam2cam) features allow for two-way video interaction between you and the webcam model. This creates a more intimate and interactive experience compared to standard webcam shows. Premium platforms offer HD quality cam-to-cam options with enhanced privacy features."
    },
    {
      question: "Are there free adult webcams available through your partners?",
      answer: "Yes, many of our partner platforms offer free adult webcams where you can watch shows without payment. These free cams typically include public shows, while private shows and additional features may require payment or tokens."
    },
    {
      question: "How do I find specific types of adult services in my area?",
      answer: "You can use our search functionality to find specific adult services in your area. Simply enter your location and preferred service type to discover verified providers nearby. We feature services throughout the UK including major cities like London, Cardiff, and more."
    },
    {
      question: "What's the difference between various webcam platforms like Chaturbate and MyFreeCams?",
      answer: "Different webcam platforms offer varying features, model selection, and payment systems. For example, Chaturbate focuses on token-based tipping in public rooms, while MyFreeCams has a community-oriented approach. Some platforms specialize in certain types of models or shows like couple cams or specific categories."
    },
    {
      question: "Are massage services like nuru massage Cardiff listed on your platform?",
      answer: "Yes, we provide information about various adult massage services including nuru massage Cardiff, happy ending massage Cardiff, and adult massage services across different UK locations. Our listings help you find reputable providers of these specialized services."
    }
  ];

  return (
    <div className="pt-16 sm:pt-20 md:pt-24 min-h-screen bg-background">
      <Helmet>
        <title>Frequently Asked Questions | Adult Webcams & Escort Services | CAMBIRDS</title>
        <meta name="description" content="Find answers to common questions about adult webcams, live cams, escort services, and more. Learn about platforms like Chaturbate, MyFreeCams, and escort services across the UK." />
        <link rel="canonical" href="https://cambirds.com/faq" />
        <meta name="keywords" content="adult webcam FAQ, live cams help, escort services questions, chaturbate help, myfreecams guide, webcam model FAQ" />
      </Helmet>
      
      <SearchEngineSchema 
        type="FAQPage"
        name="Frequently Asked Questions about Adult Webcams and Escort Services"
        description="Common questions and answers about adult webcams, live cams, escort services, and more at CAMBIRDS."
        url="https://cambirds.com/faq"
        faqItems={faqItems}
        breadcrumbs={[
          { name: "Home", item: "https://cambirds.com", position: 1 },
          { name: "FAQ", item: "https://cambirds.com/faq", position: 2 }
        ]}
      />
      
      <div className="container mx-auto px-4 max-w-[800px]">
        <div className="mb-10 text-center">
          <h1 className="text-2xl sm:text-3xl font-bold text-white mb-4">
            Frequently Asked Questions
          </h1>
          <p className="text-muted-foreground max-w-2xl mx-auto">
            Find answers to common questions about adult webcams, live cams, escort services, and more.
          </p>
        </div>
        
        <div className="glass-card p-6 sm:p-8 rounded-lg mb-8">
          <Accordion type="single" collapsible className="w-full">
            {faqItems.map((item, index) => (
              <AccordionItem key={index} value={`item-${index}`}>
                <AccordionTrigger className="text-white text-left">{item.question}</AccordionTrigger>
                <AccordionContent className="text-muted-foreground">
                  {item.answer}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        
        <div className="glass-card p-6 sm:p-8 rounded-lg mb-8">
          <h2 className="text-xl font-semibold text-white mb-4">Still Have Questions?</h2>
          <p className="text-muted-foreground mb-4">
            If you couldn't find the answer to your question, please feel free to contact us or explore our other resources:
          </p>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
            <Link to="/" className="border border-primary/20 hover:border-primary/40 p-4 rounded-lg text-center transition-colors">
              <span className="text-white font-medium">Browse Adult Webcams</span>
            </Link>
            <Link to="/terms" className="border border-primary/20 hover:border-primary/40 p-4 rounded-lg text-center transition-colors">
              <span className="text-white font-medium">Terms of Service</span>
            </Link>
          </div>
        </div>
        
        <div className="text-center mb-8">
          <p className="text-muted-foreground text-sm">
            © {new Date().getFullYear()} CAMBIRDS. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Faq;
