
import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import './index.css'

// Enhanced error handling and logging
console.log("Main script executing - Build version:", import.meta.env.MODE);

try {
  const rootElement = document.getElementById("root");
  
  if (!rootElement) {
    console.error("Root element not found in DOM");
    throw new Error("Root element not found");
  }
  
  console.log("Creating React root");
  const root = createRoot(rootElement);
  
  console.log("Rendering App component");
  root.render(
    <App />
  );
  console.log("Initial render complete");
} catch (error) {
  console.error("Critical application error:", error);
  // Try to render error state to DOM if possible
  const errorElement = document.createElement('div');
  errorElement.style.padding = '20px';
  errorElement.style.color = 'red';
  errorElement.textContent = 'Application failed to load. Please check console for details.';
  document.body.appendChild(errorElement);
}
