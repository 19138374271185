
import React from 'react';
import { Helmet } from 'react-helmet-async';

interface FaqItem {
  question: string;
  answer: string;
}

interface SearchEngineSchemaProps {
  type: 'WebPage' | 'Article' | 'Product' | 'Service' | 'FAQPage' | 'ProfilePage' | 'LocalBusiness' | 'Person';
  name: string;
  description: string;
  url?: string;
  image?: string;
  datePublished?: string;
  dateModified?: string;
  breadcrumbs?: {
    name: string;
    item: string;
    position: number;
  }[];
  faqItems?: FaqItem[];
  offers?: {
    price: string;
    priceCurrency: string;
    availability: string;
  };
  author?: {
    name: string;
    url?: string;
  };
  reviewRating?: {
    ratingValue: string;
    reviewCount: string;
  };
  location?: {
    name: string;
    address: string;
    region: string;
    country: string;
  };
  service?: {
    name: string;
    description: string;
    serviceType: string;
  };
  mainEntity?: {
    name: string;
    description: string;
    url: string;
  }[];
  person?: {
    name: string;
    gender?: string;
    description?: string;
    image?: string;
  };
}

const SearchEngineSchema = ({
  type,
  name,
  description,
  url = 'https://cambirds.com',
  image,
  datePublished,
  dateModified,
  breadcrumbs,
  faqItems,
  offers,
  author,
  reviewRating,
  location,
  service,
  mainEntity,
  person,
}: SearchEngineSchemaProps) => {
  // Base schema that's common for all types
  const baseSchema = {
    '@context': 'https://schema.org',
    '@type': type,
    name,
    description,
    url,
  };

  // Add image if provided
  if (image) {
    baseSchema['image'] = image;
  }

  // Add dates for Article type
  if (type === 'Article' && datePublished) {
    baseSchema['datePublished'] = datePublished;
    baseSchema['dateModified'] = dateModified || datePublished;
    if (author) {
      baseSchema['author'] = {
        '@type': 'Person',
        name: author.name,
        url: author.url,
      };
    }
  }

  // Add person details for Person or ProfilePage type
  if ((type === 'Person' || type === 'ProfilePage') && person) {
    if (type === 'ProfilePage') {
      baseSchema['mainEntity'] = {
        '@type': 'Person',
        name: person.name,
        description: person.description || description,
        gender: person.gender,
      };
      
      if (person.image || image) {
        baseSchema['mainEntity']['image'] = person.image || image;
      }
    } else {
      // Direct Person type properties
      baseSchema['gender'] = person.gender;
      
      if (person.image || image) {
        baseSchema['image'] = person.image || image;
      }
    }
  }

  // Add offers for Product or Service type
  if ((type === 'Product' || type === 'Service') && offers) {
    baseSchema['offers'] = {
      '@type': 'Offer',
      price: offers.price,
      priceCurrency: offers.priceCurrency,
      availability: `https://schema.org/${offers.availability}`,
    };
  }

  // Add review rating for Service, Product, or LocalBusiness
  if ((type === 'Service' || type === 'Product' || type === 'LocalBusiness') && reviewRating) {
    baseSchema['aggregateRating'] = {
      '@type': 'AggregateRating',
      ratingValue: reviewRating.ratingValue,
      reviewCount: reviewRating.reviewCount,
    };
  }

  // Handle FAQ Page type
  if (type === 'FAQPage' && faqItems && faqItems.length > 0) {
    baseSchema['mainEntity'] = faqItems.map((item) => ({
      '@type': 'Question',
      name: item.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.answer,
      },
    }));
  }

  // Add location for LocalBusiness type
  if (type === 'LocalBusiness' && location) {
    baseSchema['address'] = {
      '@type': 'PostalAddress',
      addressLocality: location.name,
      addressRegion: location.region,
      addressCountry: location.country,
    };
  }

  // Add service details for Service type
  if (type === 'Service' && service) {
    baseSchema['serviceType'] = service.serviceType;
  }

  // Add main entity for WebPage with multiple entities
  if (type === 'WebPage' && mainEntity && mainEntity.length > 0) {
    baseSchema['mainEntity'] = mainEntity.map((entity) => ({
      '@type': 'Thing',
      name: entity.name,
      description: entity.description,
      url: entity.url,
    }));
  }

  // Add breadcrumbs if provided
  let breadcrumbSchema = null;
  if (breadcrumbs && breadcrumbs.length > 0) {
    breadcrumbSchema = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs.map((crumb) => ({
        '@type': 'ListItem',
        position: crumb.position,
        name: crumb.name,
        item: crumb.item,
      })),
    };
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(baseSchema)}</script>
      {breadcrumbSchema && (
        <script type="application/ld+json">{JSON.stringify(breadcrumbSchema)}</script>
      )}
    </Helmet>
  );
};

export default SearchEngineSchema;
