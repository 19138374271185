import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRight, Heart, Shield, ExternalLink, Star, CheckSquare, MapPin } from "lucide-react";
import AdultWorkListings from "@/components/integrations/AdultWorkListings";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();
  
  // The main referral URL with PID
  const mainReferralUrl = "http://refer.adultwork.com/?PID=10817";
  
  // The registration referral URL with PID added
  const registrationReferralUrl = "https://sso.adultwork.com/Register?PID=10817&targetUrl=https%3A%2F%2Fwww.adultwork.com%2F%2FSearch.asp%3FCommandID%3D13%26CountryID%3D134";
  
  return (
    <>
      <Helmet>
        <title>CAMBIRDS - Premium Adult Webcams & Escort Directory | Live Cams</title>
        <meta name="description" content="Discover the best adult webcams, live sex cams, and escort services. Find chaturbate alternatives, myfreecams, and premium verified adult cam models." />
        <link rel="canonical" href="https://cambirds.com/" />
        <meta name="keywords" content="adult webcam, live cams, free adult cams, chaturbate, myfreecams, webcam models, live sex cams" />
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "speakable": {
              "@type": "SpeakableSpecification",
              "cssSelector": ["h1", "h2", ".speakable"]
            },
            "name": "Premium Adult Webcams & Escort Directory",
            "description": "Discover verified adult webcams, live cams, and premium adult content providers.",
            "mainEntity": {
              "@type": "ItemList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Adult Webcam Services",
                  "url": "https://cambirds.com/#webcams"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Escort Services",
                  "url": "https://cambirds.com/#escorts"
                },
                {
                  "@type": "ListItem",
                  "position": 3,
                  "name": "Dominatrix Services",
                  "url": "https://cambirds.com/#dominatrix"
                }
              ]
            }
          }
        `}</script>
      </Helmet>
      
      <section className="relative min-h-[80vh] md:h-screen md:min-h-[40rem] flex items-center hero-gradient">
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 bg-gradient-to-b from-background via-background/90 to-background/30 z-10" />
          <div className="absolute inset-0 bg-black/40 z-0" />
        </div>
        
        <div className="container mx-auto px-4 relative z-20">
          <div className="max-w-3xl mx-auto text-center mb-10 animate-slide-up">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 tracking-tight leading-tight">
              Discover Premium <span className="gradient-text">Adult Webcams</span> & Escort Services
            </h1>
            <p className="text-lg sm:text-xl text-white/80 mb-8 speakable">
              Your trusted guide to adult webcams, live cams, and premium escort services. Find the best adult entertainment from verified Escorts on Adultwork and platforms like Chaturbate, MyFreeCams and Vivastreet!
            </p>
            
            <a 
              href={registrationReferralUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-primary hover:bg-primary/90 text-white px-6 sm:px-8 py-3 sm:py-4 rounded-full mx-auto shadow-lg shadow-primary/20 transition-all hover:scale-105 inline-flex items-center"
            >
              Explore Live Webcam Models <ExternalLink className="ml-2 w-4 h-4" />
            </a>
          </div>
        </div>
      </section>
      
      <section id="webcams" className="py-12 sm:py-16 md:py-20 bg-background relative z-10">
        <div className="container mx-auto px-4">
          <div className="flex flex-col sm:flex-row justify-between items-center mb-10">
            <h2 className="text-2xl sm:text-3xl font-bold text-white mb-4 sm:mb-0">
              <span className="gradient-text">Featured</span> Escort Services
            </h2>
            <a 
              href={mainReferralUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-accent hover:text-accent/80 transition-colors"
            >
              View All Escort Services <ExternalLink size={18} className="ml-2" />
            </a>
          </div>
          
          <div className="w-full overflow-x-hidden">
            <AdultWorkListings />
          </div>
        </div>
      </section>
      
      <section id="services" className="py-12 sm:py-16 md:py-20 bg-background relative z-10">
        <div className="container mx-auto px-4">
          <div className="text-center mb-10 sm:mb-16">
            <h2 className="text-2xl sm:text-3xl font-bold text-white mb-4">
              Your Guide to <span className="gradient-text">Adult Webcams</span> & Services
            </h2>
            <p className="text-base sm:text-lg text-muted-foreground max-w-2xl mx-auto">
              We provide comprehensive guides to adult webcams, free cams, and escort services to help you make informed decisions about adult entertainment.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8">
            <div className="glass-card rounded-xl p-6 sm:p-8 text-center animate-fade-in border border-primary/20 hover:border-primary/40 transition-colors">
              <div className="w-12 h-12 sm:w-16 sm:h-16 bg-primary/10 rounded-full flex items-center justify-center mx-auto mb-6">
                <Star className="w-6 h-6 sm:w-8 sm:h-8 text-primary" />
              </div>
              <h3 className="text-lg sm:text-xl font-medium text-white mb-3">Live Webcam Reviews</h3>
              <p className="text-sm sm:text-base text-muted-foreground">
                Detailed reviews of the best adult webcam sites like Chaturbate, MyFreeCams, and more to help you find premium live cam services.
              </p>
            </div>
            
            <div className="glass-card rounded-xl p-6 sm:p-8 text-center animate-fade-in border border-accent/20 hover:border-accent/40 transition-colors" style={{ animationDelay: "100ms" }}>
              <div className="w-12 h-12 sm:w-16 sm:h-16 bg-accent/10 rounded-full flex items-center justify-center mx-auto mb-6">
                <Heart className="w-6 h-6 sm:w-8 sm:h-8 text-accent" />
              </div>
              <h3 className="text-lg sm:text-xl font-medium text-white mb-3">Escort Services</h3>
              <p className="text-sm sm:text-base text-muted-foreground">
                Find verified escort services across the UK including London, Cardiff, and other major cities with detailed profiles and reviews.
              </p>
            </div>
            
            <div className="glass-card rounded-xl p-6 sm:p-8 text-center animate-fade-in border border-primary/20 hover:border-primary/40 transition-colors" style={{ animationDelay: "200ms" }}>
              <div className="w-12 h-12 sm:w-16 sm:h-16 bg-primary/10 rounded-full flex items-center justify-center mx-auto mb-6">
                <Shield className="w-6 h-6 sm:w-8 sm:h-8 text-primary" />
              </div>
              <h3 className="text-lg sm:text-xl font-medium text-white mb-3">Verified Profiles</h3>
              <p className="text-sm sm:text-base text-muted-foreground">
                All our webcam models and escort listings are verified to ensure safe and reliable adult entertainment experiences.
              </p>
            </div>
          </div>
        </div>
      </section>
      
      <section id="popular-categories" className="py-12 sm:py-16 md:py-20 bg-background relative z-10">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl font-bold text-white mb-8 text-center">
            Popular <span className="gradient-text">Adult Webcam</span> Categories
          </h2>
          
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-10">
            <Link to="/search?category=asian" className="glass-card p-4 text-center rounded-lg hover:border-accent/40 border border-accent/20 transition-all">
              <h3 className="text-lg font-medium text-white">Asian Cams</h3>
              <p className="text-sm text-muted-foreground mt-2">Live asian webcam models</p>
            </Link>
            
            <Link to="/search?category=latina" className="glass-card p-4 text-center rounded-lg hover:border-accent/40 border border-accent/20 transition-all">
              <h3 className="text-lg font-medium text-white">Latina Cams</h3>
              <p className="text-sm text-muted-foreground mt-2">Hot latina webcam shows</p>
            </Link>
            
            <Link to="/search?category=bbw" className="glass-card p-4 text-center rounded-lg hover:border-accent/40 border border-accent/20 transition-all">
              <h3 className="text-lg font-medium text-white">BBW Cams</h3>
              <p className="text-sm text-muted-foreground mt-2">Plus size webcam models</p>
            </Link>
            
            <Link to="/search?category=mature" className="glass-card p-4 text-center rounded-lg hover:border-accent/40 border border-accent/20 transition-all">
              <h3 className="text-lg font-medium text-white">Mature Cams</h3>
              <p className="text-sm text-muted-foreground mt-2">Experienced webcam performers</p>
            </Link>
          </div>
          
          <div className="text-center">
            <Link to="/search" className="inline-flex items-center text-accent hover:text-accent/80 transition-colors">
              View All Categories <ArrowRight size={18} className="ml-2" />
            </Link>
          </div>
        </div>
      </section>
      
      <section id="escort-services" className="py-12 sm:py-16 md:py-20 bg-background relative z-10">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl font-bold text-white mb-8 text-center">
            Premium <span className="gradient-text">Escort Services</span> in the UK
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="glass-card p-6 rounded-lg">
              <div className="flex items-center mb-4">
                <MapPin className="text-primary mr-2" />
                <h3 className="text-lg font-medium text-white">London Escorts</h3>
              </div>
              <p className="text-sm text-muted-foreground mb-4">
                Find verified Korean escorts in London, Chinese escorts in London, and other premium escort services in the capital.
              </p>
              <Link to="/search?location=london" className="text-accent hover:text-accent/80 text-sm inline-flex items-center">
                Find London Escorts <ArrowRight size={14} className="ml-1" />
              </Link>
            </div>
            
            <div className="glass-card p-6 rounded-lg">
              <div className="flex items-center mb-4">
                <MapPin className="text-primary mr-2" />
                <h3 className="text-lg font-medium text-white">Cardiff Services</h3>
              </div>
              <p className="text-sm text-muted-foreground mb-4">
                Discover adult massage in Cardiff, happy ending massage Cardiff, and nuru massage services in Wales.
              </p>
              <Link to="/search?location=cardiff" className="text-accent hover:text-accent/80 text-sm inline-flex items-center">
                Find Cardiff Services <ArrowRight size={14} className="ml-1" />
              </Link>
            </div>
            
            <div className="glass-card p-6 rounded-lg">
              <div className="flex items-center mb-4">
                <MapPin className="text-primary mr-2" />
                <h3 className="text-lg font-medium text-white">Dominatrix Services</h3>
              </div>
              <p className="text-sm text-muted-foreground mb-4">
                Connect with experienced dominatrix professionals in Northwest dominatrix, Wales dominatrix, and Kent dominatrix services.
              </p>
              <Link to="/search?service=dominatrix" className="text-accent hover:text-accent/80 text-sm inline-flex items-center">
                Find Dominatrix Services <ArrowRight size={14} className="ml-1" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      
      <section id="webcam-platforms" className="py-12 sm:py-16 md:py-20 bg-background relative z-10">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl font-bold text-white mb-8 text-center">
            Top <span className="gradient-text">Webcam Platforms</span>
          </h2>
          
          <div className="glass-card p-6 sm:p-8 rounded-lg mb-10">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-xl font-semibold text-white mb-4">Chaturbate Alternatives</h3>
                <p className="text-sm text-muted-foreground mb-4">
                  Discover alternatives to Chaturbate with our curated selection of premium adult webcam platforms offering free and paid live cam shows.
                </p>
                <ul className="space-y-2">
                  <li className="flex items-center text-sm text-muted-foreground">
                    <CheckSquare className="text-accent mr-2 h-4 w-4" /> Free adult cams with no registration
                  </li>
                  <li className="flex items-center text-sm text-muted-foreground">
                    <CheckSquare className="text-accent mr-2 h-4 w-4" /> HD quality webcam streams
                  </li>
                  <li className="flex items-center text-sm text-muted-foreground">
                    <CheckSquare className="text-accent mr-2 h-4 w-4" /> Interactive cam-2-cam features
                  </li>
                </ul>
              </div>
              
              <div>
                <h3 className="text-xl font-semibold text-white mb-4">MyFreeCams & LiveJasmin</h3>
                <p className="text-sm text-muted-foreground mb-4">
                  Compare premium platforms like MyFreeCams, LiveJasmin, and IMLive to find the best adult webcam experience for your preferences.
                </p>
                <ul className="space-y-2">
                  <li className="flex items-center text-sm text-muted-foreground">
                    <CheckSquare className="text-accent mr-2 h-4 w-4" /> Verified webcam models
                  </li>
                  <li className="flex items-center text-sm text-muted-foreground">
                    <CheckSquare className="text-accent mr-2 h-4 w-4" /> Private shows and live sex cams
                  </li>
                  <li className="flex items-center text-sm text-muted-foreground">
                    <CheckSquare className="text-accent mr-2 h-4 w-4" /> Couple cams and group shows
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          <div className="text-center">
            <a 
              href={registrationReferralUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-primary hover:bg-primary/90 text-white px-6 sm:px-8 py-3 sm:py-4 rounded-full inline-flex items-center mx-auto shadow-lg shadow-primary/20 transition-all hover:scale-105"
            >
              Explore Live Webcam Models Now <ExternalLink className="ml-2 w-4 h-4" />
            </a>
          </div>
        </div>
      </section>
      
      <section className="py-12 sm:py-16 md:py-20 bg-gradient-to-b from-background to-card relative z-10">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-2xl sm:text-3xl font-bold text-white mb-4 sm:mb-6">
            Ready to <span className="gradient-text">Explore</span>?
          </h2>
          <p className="text-base sm:text-lg text-muted-foreground max-w-2xl mx-auto mb-8 sm:mb-10">
            Visit our trusted partner platform to discover thousands of verified adult webcam models and escort service providers.
          </p>
          
          <a 
            href={registrationReferralUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-primary hover:bg-primary/90 text-white px-6 sm:px-8 py-3 sm:py-4 rounded-full inline-flex items-center mx-auto shadow-lg shadow-primary/20 transition-all hover:scale-105"
          >
            Find Your Perfect Match <ExternalLink className="ml-2 w-4 h-4" />
          </a>
        </div>
      </section>
      
      <section className="py-12 bg-background/80">
        <div className="container mx-auto px-4">
          <h2 className="sr-only">Additional Information</h2>
          <div className="prose prose-sm max-w-none text-muted-foreground/60">
            <p>
              CAMBIRDS provides access to premium adult webcams and escort services across the UK. Our platform features live cams, free adult webcams, and verified escort services in locations including London, Cardiff, and other major UK cities. Find alternatives to popular platforms like Chaturbate, MyFreeCams, and ImLive, with features such as cam-to-cam and free adult chat.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
