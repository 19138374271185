
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import SearchEngineSchema from './SearchEngineSchema';
import AdultWorkListings from "@/components/integrations/AdultWorkListings";

interface SeoPageLayoutProps {
  title: string;
  description: string;
  canonicalUrl: string;
  pageType: 'escorts' | 'dominatrix' | 'massage' | 'services' | 'mistress';
  location: string;
  subLocation?: string;
  breadcrumbs: {
    name: string;
    link: string;
  }[];
  h1: string;
  h2?: string;
  introText: string;
  contentSections?: {
    title: string;
    content: string;
  }[];
  relatedLinks?: {
    title: string;
    links: {
      text: string;
      url: string;
    }[];
  }[];
  schemaType: 'WebPage' | 'Service' | 'LocalBusiness';
  children?: React.ReactNode;
}

const SeoPageLayout: React.FC<SeoPageLayoutProps> = ({
  title,
  description,
  canonicalUrl,
  pageType,
  location,
  subLocation,
  breadcrumbs,
  h1,
  h2,
  introText,
  contentSections = [],
  relatedLinks = [],
  schemaType,
  children,
}) => {
  const formattedBreadcrumbs = breadcrumbs.map((crumb, index) => ({
    name: crumb.name,
    item: crumb.link,
    position: index + 1,
  }));

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="website" />
      </Helmet>

      <SearchEngineSchema
        type={schemaType}
        name={h1}
        description={description}
        url={canonicalUrl}
        breadcrumbs={formattedBreadcrumbs}
        location={schemaType === 'LocalBusiness' ? {
          name: location,
          address: `${location}, UK`,
          region: location,
          country: 'United Kingdom'
        } : undefined}
        service={schemaType === 'Service' ? {
          name: h1,
          description: description,
          serviceType: pageType
        } : undefined}
      />

      <div className="pt-16 sm:pt-20 md:pt-24 min-h-screen bg-background">
        <div className="container mx-auto px-4 max-w-[1400px]">
          <Breadcrumb className="my-4">
            <BreadcrumbList>
              {breadcrumbs.map((crumb, index) => (
                <React.Fragment key={index}>
                  <BreadcrumbItem>
                    <BreadcrumbLink asChild>
                      <Link to={crumb.link}>{crumb.name}</Link>
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  {index < breadcrumbs.length - 1 && <BreadcrumbSeparator />}
                </React.Fragment>
              ))}
            </BreadcrumbList>
          </Breadcrumb>

          <div className="mb-6 sm:mb-8">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-white mb-4">
              {h1}
            </h1>
            {h2 && (
              <h2 className="text-xl sm:text-2xl text-accent/80 mb-4">
                {h2}
              </h2>
            )}
            <div className="prose prose-sm sm:prose prose-invert max-w-none mb-8">
              <p>{introText}</p>
            </div>
          </div>

          {children}

          <div className="my-8">
            <AdultWorkListings />
          </div>

          {contentSections.length > 0 && (
            <div className="my-12 prose prose-sm sm:prose prose-invert max-w-none">
              {contentSections.map((section, index) => (
                <div key={index} className="mb-6">
                  <h3 className="text-xl font-semibold mb-3">{section.title}</h3>
                  <p>{section.content}</p>
                </div>
              ))}
            </div>
          )}

          {relatedLinks.length > 0 && (
            <div className="my-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {relatedLinks.map((section, index) => (
                <div key={index} className="glass-card p-4 rounded-lg">
                  <h3 className="text-lg font-medium text-white mb-3">{section.title}</h3>
                  <ul className="space-y-2">
                    {section.links.map((link, linkIndex) => (
                      <li key={linkIndex} className="text-sm">
                        <Link to={link.url} className="text-accent hover:text-accent/80 transition-colors">
                          {link.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}

          <div className="my-8 py-4 border-t border-white/10">
            <p className="text-sm text-muted-foreground">
              All services are provided by independent adults. CAMBIRDS is solely a directory service
              and does not provide escort, dominatrix, or massage services directly.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeoPageLayout;
