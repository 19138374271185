import { useEffect, useState } from 'react';
import { Loader2 } from 'lucide-react';

declare global {
  interface Window {
    aw?: any;
  }
}

const AdultWorkListings = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // The main referral URL with PID
  const mainReferralUrl = "http://refer.adultwork.com/?PID=10817";
  
  // The registration referral URL with PID added
  const registrationReferralUrl = "https://sso.adultwork.com/Register?PID=10817&targetUrl=https%3A%2F%2Fwww.adultwork.com%2F%2FSearch.asp%3FCommandID%3D13%26CountryID%3D134";

  useEffect(() => {
    // Handle responsive layout
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Set a timeout to show loading state for minimum time
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    // Calculate columns based on screen width
    const getColumns = () => {
      if (windowWidth < 640) return 1;
      if (windowWidth < 768) return 2;
      if (windowWidth < 1024) return 3;
      return 4;
    };

    // Load AdultWork SDK
    const script = document.createElement('script');
    script.id = 'adultwork-jssdk';
    script.src = '//cdn.adultwork.com/platform/sdk/sdk.js?v=1.4#APIKey=OH2t37Byxrnsz7Os1LyZjqVNvyQKMCKTrrp7R8N3GQY&clientId=CdbUfno2VITPNfVK1NQ-D1q1WfZD7w5WT8XMZVdKEsI';
    script.async = true;
    
    script.onload = () => {
      setIsScriptLoaded(true);
      clearTimeout(loadingTimeout);
      setIsLoading(false);

      // Override links to ensure they use our referral URL
      if (window.aw) {
        try {
          // This will execute after the SDK initializes
          setTimeout(() => {
            // Update profile links
            const profileLinks = document.querySelectorAll('.aw-profile-card a');
            if (profileLinks && profileLinks.length > 0) {
              profileLinks.forEach(link => {
                // Make sure link is an Element before using setAttribute
                if (link instanceof Element) {
                  link.setAttribute('href', mainReferralUrl);
                }
              });
            }
            
            // Update pin buttons specifically
            const pinButtons = document.querySelectorAll('.aw-plugin-listings .aw-profile-card .aw-profile-pin-button');
            if (pinButtons && pinButtons.length > 0) {
              pinButtons.forEach(button => {
                // Make sure button is an Element before cloning and replacing
                if (button instanceof Element && button.parentNode instanceof Element) {
                  // Remove any existing event listeners by cloning and replacing the button
                  const newButton = button.cloneNode(true);
                  button.parentNode.replaceChild(newButton, button);
                  
                  // Add new event listener with our referral URL
                  if (newButton instanceof Element) {
                    newButton.addEventListener('click', (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      window.open(registrationReferralUrl, '_blank');
                      return false;
                    });
                  }
                }
              });
            }
            
            // Add a mutation observer to handle dynamically added elements
            const targetNode = document.querySelector('.aw-plugin-profile-listings');
            if (targetNode) {
              const observer = new MutationObserver((mutations) => {
                mutations.forEach(() => {
                  // Update any new links that may have been added
                  const newProfileLinks = document.querySelectorAll('.aw-profile-card a');
                  if (newProfileLinks && newProfileLinks.length > 0) {
                    newProfileLinks.forEach(link => {
                      if (link instanceof Element) {
                        link.setAttribute('href', mainReferralUrl);
                      }
                    });
                  }
                  
                  // Update any new pin buttons
                  const newPinButtons = document.querySelectorAll('.aw-plugin-listings .aw-profile-card .aw-profile-pin-button');
                  if (newPinButtons && newPinButtons.length > 0) {
                    newPinButtons.forEach(button => {
                      if (button instanceof Element && 
                          !button.hasAttribute('data-modified') && 
                          button.parentNode instanceof Element) {
                        
                        const newButton = button.cloneNode(true);
                        button.parentNode.replaceChild(newButton, button);
                        
                        if (newButton instanceof Element) {
                          newButton.setAttribute('data-modified', 'true');
                          newButton.addEventListener('click', (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.open(registrationReferralUrl, '_blank');
                            return false;
                          });
                        }
                      }
                    });
                  }
                });
              });
              
              // Start observing
              observer.observe(targetNode, { childList: true, subtree: true });
            }
          }, 1000);
        } catch (err) {
          console.error('Error updating AdultWork links:', err);
        }
      }
    };
    
    const firstScript = document.getElementsByTagName('script')[0];
    if (firstScript && firstScript.parentNode && !document.getElementById('adultwork-jssdk')) {
      firstScript.parentNode.insertBefore(script, firstScript);
    }

    return () => {
      // Cleanup on unmount
      window.removeEventListener('resize', handleResize);
      clearTimeout(loadingTimeout);
      const existingScript = document.getElementById('adultwork-jssdk');
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, [windowWidth, mainReferralUrl, registrationReferralUrl]);

  // Calculate columns based on screen width
  const getColumns = () => {
    if (windowWidth < 640) return 1;
    if (windowWidth < 768) return 2;
    if (windowWidth < 1024) return 3;
    return 4;
  };

  return (
    <>
      {isLoading && (
        <div className="flex justify-center items-center py-16">
          <Loader2 className="w-8 h-8 text-accent animate-spin" />
          <span className="ml-2 text-muted-foreground">Loading profiles...</span>
        </div>
      )}
      
      <div className="aw-plugin-profile-listings w-full max-w-[1400px] mx-auto overflow-x-hidden"
        data-pagingbuttonposition="top"
        data-primarycolor="D946EF" 
        data-secondarycolor="8B5CF6"
        data-rowcolor="2A1B3D"
        data-altrowcolor="231532"
        data-imagesizes="0"
        data-borderradius="16"
        data-tvts="0"
        data-singlepageonly="false"
        data-columns={getColumns()}
        data-marginwidth="12"
        data-rows="10"
        data-profilesperpage="20"
        data-pagenumber="1"
        data-gender="female"
        data-availabletoday="false"
        data-duplicatecheck="true"
        data-usecss="css/.aw-plugin-listings { font-family: 'Inter', sans-serif; width: 100% !important; max-width: 100% !important; margin: 0 auto !important; } 
          .aw-plugin-listings .aw-profile-card { border-radius: 16px; overflow: hidden; transition: all 0.3s; box-shadow: 0 8px 28px rgba(0,0,0,0.25); width: 100% !important; margin: 0 !important; background: linear-gradient(180deg, #2A1B3D 0%, #231532 100%); min-width: 250px; max-width: 100% !important; } 
          .aw-plugin-listings .aw-profile-card:hover { transform: translateY(-5px); box-shadow: 0 12px 36px rgba(217, 70, 239, 0.2); }
          .aw-plugin-listings .aw-paging { margin: 32px 0; text-align: center !important; }
          .aw-plugin-listings .aw-paging a { background: #2A1B3D !important; color: #D946EF !important; border: 1px solid #D946EF !important; border-radius: 8px !important; padding: 8px 16px !important; margin: 0 4px !important; transition: all 0.3s !important; }
          .aw-plugin-listings .aw-paging a:hover { background: #D946EF !important; color: white !important; }
          .aw-plugin-listings .aw-profile-image { width: 100% !important; height: auto !important; aspect-ratio: 3/4; object-fit: cover; }
          .aw-plugin-listings .aw-profile-details { padding: 20px !important; }
          .aw-plugin-listings .aw-profile-name { color: #fff !important; font-weight: 600 !important; font-size: 18px !important; margin-bottom: 8px !important; }
          .aw-plugin-listings .aw-profile-location { color: rgba(255,255,255,0.7) !important; font-size: 14px !important; }
          .aw-plugin-profile-listings { display: grid !important; grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important; gap: 16px !important; width: 100% !important; margin: 0 auto !important; }"
      />
    </>
  );
};

export default AdultWorkListings;
