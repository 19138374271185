
import React from 'react';
import SeoPageLayout from '@/components/seo/SeoPageLayout';

const ChineseEscortsLondon = () => {
  return (
    <SeoPageLayout
      title="Chinese Escorts London | Authentic Asian Companions | CAMBIRDS"
      description="Discover premium Chinese escorts in London. Verified Asian companions available for outcalls and incalls. Find beautiful Chinese escorts across Central London areas."
      canonicalUrl="https://cambirds.com/escorts/london/chinese"
      pageType="escorts"
      location="London"
      subLocation="Chinese"
      breadcrumbs={[
        { name: 'Home', link: '/' },
        { name: 'Escorts', link: '/search?type=escorts' },
        { name: 'London', link: '/search?location=london' },
        { name: 'Chinese Escorts', link: '/escorts/london/chinese' },
      ]}
      h1="Chinese Escorts in London"
      h2="Premium Asian Escort Services"
      introText="Connect with authentic Chinese escorts in London providing exceptional companionship and escort services. Our platform features verified Chinese companions offering discreet and professional experiences across Central London and surrounding areas."
      contentSections={[
        {
          title: "Meet Chinese Escorts in London",
          content: "London's Chinese escorts offer a unique blend of Eastern charm and sophisticated companionship. Whether you're seeking conversation, companionship, or more intimate encounters, our listed Chinese escorts provide attentive and personalized services tailored to your preferences."
        },
        {
          title: "Chinese Escort Services Available",
          content: "Our verified Chinese escorts in London provide a range of services including dinner dates, event accompaniment, travel companionship, and intimate encounters. All profiles are carefully verified to ensure you receive the highest quality service from authentic companions."
        },
        {
          title: "London Areas with Chinese Escorts",
          content: "Find Chinese escorts throughout London, particularly in areas like Soho, Chelsea, Mayfair, and Earl's Court. Many offer both incall services at discreet, comfortable locations and outcall services to hotels and private residences across London."
        }
      ]}
      relatedLinks={[
        {
          title: "Related Escort Services",
          links: [
            { text: "Korean Escorts London", url: "/escorts/london/korean" },
            { text: "Japanese Escorts London", url: "/escorts/london/japanese" },
            { text: "Thai Escorts London", url: "/escorts/london/thai" },
          ]
        },
        {
          title: "London Areas",
          links: [
            { text: "Soho Escorts", url: "/escorts/london/soho" },
            { text: "Chelsea Escorts", url: "/escorts/london/chelsea" },
            { text: "Earl's Court Escorts", url: "/escorts/london/earls-court" },
          ]
        }
      ]}
      schemaType="LocalBusiness"
    />
  );
};

export default ChineseEscortsLondon;
