
import React from 'react';
import SeoPageLayout from '@/components/seo/SeoPageLayout';

const EscortsIndex = () => {
  return (
    <SeoPageLayout
      title="UK Escorts | Professional Escort Services | CAMBIRDS"
      description="Find verified escorts throughout the UK. Professional companions available for incalls and outcalls across Britain including London, Manchester, Birmingham and more."
      canonicalUrl="https://cambirds.com/escorts"
      pageType="escorts"
      location="UK"
      breadcrumbs={[
        { name: 'Home', link: '/' },
        { name: 'Escorts', link: '/escorts' },
      ]}
      h1="UK Escort Services"
      h2="Premium Companion Services Nationwide"
      introText="Discover professional escort services across the United Kingdom with our curated selection of verified companions. Our platform features sophisticated escorts offering discreet companionship throughout major cities and regions including London, Manchester, Birmingham, and beyond."
      contentSections={[
        {
          title: "Professional UK Escort Services",
          content: "Our listed escorts provide high-quality companionship for various occasions including dinner dates, social events, business functions, and intimate encounters. All profiles are carefully verified to ensure authentic experiences with professional companions who prioritize discretion and client satisfaction."
        },
        {
          title: "Available Escort Services",
          content: "UK escorts offer flexible booking options including incall services at comfortable, private locations and outcall services to hotels, residences, and business venues. Whether you're a local resident or visiting the UK, our escorts create memorable experiences tailored to your preferences."
        },
        {
          title: "Finding Escorts in Your Area",
          content: "Browse our extensive directory to find verified escorts in major cities and regions across the UK. Our search options allow you to filter by location, services, and other preferences to find the perfect companion for your needs."
        }
      ]}
      relatedLinks={[
        {
          title: "Popular Escort Locations",
          links: [
            { text: "London Escorts", url: "/escorts/london" },
            { text: "Manchester Escorts", url: "/escorts/manchester" },
            { text: "Birmingham Escorts", url: "/escorts/birmingham" },
          ]
        },
        {
          title: "Specialized Escort Services",
          links: [
            { text: "Korean Escorts London", url: "/escorts/london/korean" },
            { text: "Chinese Escorts London", url: "/escorts/london/chinese" },
            { text: "VIP Escorts UK", url: "/escorts/vip" },
          ]
        }
      ]}
      schemaType="LocalBusiness"
    />
  );
};

export default EscortsIndex;
