
import { useToast } from "@/hooks/use-toast";
import AdultWorkListings from "@/components/integrations/AdultWorkListings";

const SearchResults = () => {
  const { toast } = useToast();
  
  return (
    <div className="pt-16 sm:pt-20 md:pt-24 min-h-screen bg-background">
      <div className="container mx-auto px-4 max-w-[1400px]">
        <div className="mb-6 sm:mb-8 text-center">
          <h1 className="text-2xl sm:text-3xl font-bold text-white mb-4 sm:mb-6">
            Browse Models
          </h1>
        </div>
        
        <div className="mb-6 text-center">
          <p className="text-muted-foreground">
            Showing all results
          </p>
        </div>
        
        {/* Real profile listings from AdultWork */}
        <div className="w-full max-w-[1400px] mx-auto overflow-x-hidden">
          <AdultWorkListings />
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
