
import React from 'react';
import SeoPageLayout from '@/components/seo/SeoPageLayout';

const MassageIndex = () => {
  return (
    <SeoPageLayout
      title="Adult Massage Services UK | Erotic & Sensual Massage | CAMBIRDS"
      description="Discover premium adult massage services across the UK. Find professional sensual, erotic, and nuru massage therapists offering relaxation and pleasure in discreet locations."
      canonicalUrl="https://cambirds.com/massage"
      pageType="massage"
      location="UK"
      breadcrumbs={[
        { name: 'Home', link: '/' },
        { name: 'Massage', link: '/massage' },
      ]}
      h1="Adult Massage Services UK"
      h2="Professional Sensual & Erotic Massage"
      introText="Explore premium adult massage services throughout the United Kingdom. Our directory connects you with professional massage providers specializing in sensual, erotic, tantric, and nuru massages across major cities and regions including London, Manchester, Birmingham, and beyond."
      contentSections={[
        {
          title: "UK Adult Massage Services",
          content: "Our listed massage therapists offer a variety of adult massage styles including sensual massage, nuru massage, body-to-body, tantric massage, and happy ending services. Each provider delivers professional experiences in clean, comfortable environments with a focus on both relaxation and pleasure."
        },
        {
          title: "Massage Locations Across the UK",
          content: "Find adult massage services throughout the UK, with providers located in major cities and regions. Most offer incall services at private, discreet massage parlors, while some also provide outcall services to hotels and residences for your convenience and comfort."
        },
        {
          title: "Choosing a Massage Service",
          content: "When selecting an adult massage service, consider the therapist's experience, massage styles offered, and facility amenities. Our directory provides detailed information about each provider, including services, pricing, and location, helping you find the perfect massage experience to meet your needs."
        }
      ]}
      relatedLinks={[
        {
          title: "Popular Massage Locations",
          links: [
            { text: "London Massage", url: "/massage/london" },
            { text: "Manchester Massage", url: "/massage/manchester" },
            { text: "Birmingham Massage", url: "/massage/birmingham" },
          ]
        },
        {
          title: "Specialized Massage Services",
          links: [
            { text: "Nuru Massage UK", url: "/massage/nuru" },
            { text: "Tantric Massage London", url: "/massage/london/tantric" },
            { text: "Happy Ending Massage Cardiff", url: "/massage/cardiff/happy-ending" },
          ]
        }
      ]}
      schemaType="Service"
    />
  );
};

export default MassageIndex;
