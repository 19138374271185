
import React from 'react';
import SeoPageLayout from '@/components/seo/SeoPageLayout';

const SussexDominatrix = () => {
  return (
    <SeoPageLayout
      title="Sussex Dominatrix | Professional BDSM Services | CAMBIRDS"
      description="Find experienced dominatrices in Sussex offering professional BDSM and mistress services in Brighton, Eastbourne, Horsham, and across East and West Sussex."
      canonicalUrl="https://cambirds.com/dominatrix/sussex"
      pageType="dominatrix"
      location="Sussex"
      breadcrumbs={[
        { name: 'Home', link: '/' },
        { name: 'Dominatrix', link: '/search?type=dominatrix' },
        { name: 'Sussex', link: '/dominatrix/sussex' },
      ]}
      h1="Sussex Dominatrix Services"
      h2="Professional BDSM & Mistress Experiences"
      introText="Discover professional dominatrix services across Sussex, including Brighton, Eastbourne, Horsham, and surrounding areas. Our directory features experienced mistresses providing premium BDSM sessions, discipline services, and fetish fulfillment throughout East and West Sussex."
      contentSections={[
        {
          title: "Professional Dominatrix Services in Sussex",
          content: "Our listed Sussex dominatrices offer a comprehensive range of BDSM and femdom services, including corporal punishment, bondage, role play, humiliation, and specialized fetish sessions. Each dominatrix provides safe, consensual experiences with clear boundaries and professional discretion."
        },
        {
          title: "Sussex Dominatrix Locations",
          content: "Find professional mistresses across Sussex, with many operating from private dungeons and studios in major towns including Brighton, Eastbourne, Worthing, Crawley, and Horsham. Many also offer sessions in appropriate hotel rooms or provide outcall services to suitable locations."
        },
        {
          title: "Choosing a Sussex Dominatrix",
          content: "When selecting a dominatrix in Sussex, consider their experience, specialties, and session styles. Our directory provides detailed profiles outlining each mistress's services, boundaries, and session requirements, helping you find the perfect match for your BDSM desires and fantasies."
        }
      ]}
      relatedLinks={[
        {
          title: "Related Dominatrix Services",
          links: [
            { text: "Kent Dominatrix", url: "/dominatrix/kent" },
            { text: "London Dominatrix", url: "/dominatrix/london" },
            { text: "Hampshire Dominatrix", url: "/dominatrix/hampshire" },
          ]
        },
        {
          title: "Sussex Areas",
          links: [
            { text: "Brighton Mistress", url: "/mistress/brighton" },
            { text: "Eastbourne BDSM", url: "/bdsm/eastbourne" },
            { text: "Worthing Dominatrix", url: "/dominatrix/worthing" },
          ]
        }
      ]}
      schemaType="Service"
    />
  );
};

export default SussexDominatrix;
