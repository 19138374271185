
import { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { 
  MapPin, 
  Calendar, 
  Phone, 
  Heart,
  ChevronRight,
  RefreshCw,
  CheckCircle,
  Star
} from "lucide-react";
import ProfileGallery from "@/components/profiles/ProfileGallery";
import { fetchProfileDetails } from "@/services/api";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";

const ProfileDetail = () => {
  const { id } = useParams();
  const [isFavorite, setIsFavorite] = useState(false);
  const { toast } = useToast();
  
  const { data: profile, isLoading, error, refetch } = useQuery({
    queryKey: ["profile", id],
    queryFn: () => fetchProfileDetails({ userId: Number(id) }),
    retry: 1,
    meta: {
      onError: (err: Error) => {
        toast({
          title: "Error",
          description: "Failed to load profile data. Please try again.",
          variant: "destructive"
        });
        console.error("Profile query error:", err);
      }
    }
  });
  
  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };
  
  const formatAvailability = (availability: any) => {
    if (!availability) return "Not specified";
    
    const availableDays = Object.entries(availability)
      .filter(([key, value]) => value === true && key !== "twentyFourHours")
      .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1));
    
    if (availability.twentyFourHours) return "Available 24/7";
    if (availableDays.length === 0) return "By appointment only";
    if (availableDays.length === 7) return "Available every day";
    
    return availableDays.join(", ");
  };
  
  if (isLoading) {
    return (
      <div className="pt-24 min-h-screen bg-background">
        <div className="container mx-auto px-4">
          <div className="glass-card rounded-xl p-8 animate-pulse-slow">
            <div className="h-8 bg-muted/20 rounded w-1/3 mb-4" />
            <div className="h-80 bg-muted/20 rounded-xl mb-6" />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="col-span-2">
                <div className="h-6 bg-muted/20 rounded w-1/2 mb-3" />
                <div className="h-4 bg-muted/20 rounded w-full mb-2" />
                <div className="h-4 bg-muted/20 rounded w-5/6 mb-2" />
                <div className="h-4 bg-muted/20 rounded w-4/5" />
              </div>
              <div>
                <div className="h-6 bg-muted/20 rounded w-1/2 mb-3" />
                <div className="h-20 bg-muted/20 rounded mb-3" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="pt-24 min-h-screen bg-background">
        <div className="container mx-auto px-4">
          <div className="glass-card rounded-xl p-8 text-center">
            <h2 className="text-2xl font-bold text-white mb-4">Error Loading Profile</h2>
            <p className="text-muted-foreground mb-6">
              We encountered an issue while connecting to the AdultWork API. This could be due to network issues or API restrictions.
            </p>
            <div className="flex justify-center gap-4">
              <Button 
                onClick={() => refetch()}
                className="bg-primary text-white hover:bg-primary/90 flex items-center"
              >
                <RefreshCw size={16} className="mr-2" />
                Try Again
              </Button>
              <Link to="/search" className="bg-muted text-white px-6 py-3 rounded-full inline-block">
                Browse All Profiles
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  if (!profile) {
    return (
      <div className="pt-24 min-h-screen bg-background">
        <div className="container mx-auto px-4">
          <div className="glass-card rounded-xl p-8 text-center">
            <h2 className="text-2xl font-bold text-white mb-4">Profile Not Found</h2>
            <p className="text-muted-foreground mb-6">
              We couldn't find the profile you're looking for. The profile ID may be invalid or the profile may have been removed.
            </p>
            <Link to="/search" className="bg-primary text-white px-6 py-3 rounded-full inline-block">
              Browse All Profiles
            </Link>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="pt-24 min-h-screen bg-background">
      <div className="container mx-auto px-4 pb-16">
        <div className="mb-6">
          <nav className="flex text-sm text-muted-foreground">
            <Link to="/" className="hover:text-white transition-colors">Home</Link>
            <ChevronRight size={16} className="mx-2" />
            <Link to="/search" className="hover:text-white transition-colors">Search</Link>
            <ChevronRight size={16} className="mx-2" />
            <span className="text-white">{profile.nickname}</span>
          </nav>
        </div>
        
        <div className="flex flex-wrap items-start justify-between mb-6">
          <div>
            <div className="flex items-center">
              <h1 className="text-3xl font-bold text-white mr-3">{profile.nickname}</h1>
              {profile.verificationBadge && (
                <span className="bg-primary/90 text-white p-1 rounded-full flex items-center" title="Verified Profile">
                  <CheckCircle size={16} />
                </span>
              )}
            </div>
            <div className="flex items-center mt-2">
              <MapPin size={16} className="text-muted-foreground mr-2" />
              <span className="text-muted-foreground">
                {profile.location.city}, {profile.location.region}, {profile.location.country}
              </span>
            </div>
          </div>
          
          <div className="flex mt-4 sm:mt-0">
            <button
              onClick={toggleFavorite}
              className={`p-2 rounded-full mr-3 transition-colors ${
                isFavorite ? "bg-destructive text-white" : "bg-muted text-white"
              }`}
              aria-label={isFavorite ? "Remove from favorites" : "Add to favorites"}
            >
              <Heart size={20} fill={isFavorite ? "currentColor" : "none"} />
            </button>
            <a 
              href={`tel:${profile.contactInfo.phone}`} 
              className="bg-primary text-white px-5 py-2 rounded-full flex items-center shadow-lg hover:shadow-primary/20 transition-all"
            >
              <Phone size={18} className="mr-2" />
              Contact Now
            </a>
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="md:col-span-2">
            <div className="glass-card rounded-xl p-6 mb-8">
              <ProfileGallery images={profile.images} />
            </div>
            
            <div className="glass-card rounded-xl p-6 mb-8">
              <h2 className="text-xl font-medium text-white mb-4">About {profile.nickname}</h2>
              <div className="space-y-4 text-muted-foreground">
                <p>{profile.summary}</p>
              </div>
            </div>
            
            <div className="glass-card rounded-xl p-6">
              <h2 className="text-xl font-medium text-white mb-4">Services Offered</h2>
              <div className="flex flex-wrap gap-2">
                {profile.services.map((service, index) => (
                  <span 
                    key={index} 
                    className="bg-muted text-white px-3 py-1.5 rounded-full text-sm"
                  >
                    {service}
                  </span>
                ))}
              </div>
            </div>
          </div>
          
          <div>
            <div className="glass-card rounded-xl p-6 mb-6">
              <h3 className="text-lg font-medium text-white mb-3">Rating</h3>
              <div className="flex items-center">
                <div className="flex items-center mr-3">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <Star 
                      key={star} 
                      size={18} 
                      className="text-yellow-400 mr-1" 
                      fill={star <= Math.round(profile.rating.overall) ? "currentColor" : "none"} 
                    />
                  ))}
                </div>
                <span className="text-white font-medium">{profile.rating.overall.toFixed(1)}</span>
                <span className="text-muted-foreground ml-2">({profile.rating.count} reviews)</span>
              </div>
            </div>
            
            <div className="glass-card rounded-xl p-6 mb-6">
              <h3 className="text-lg font-medium text-white mb-4">Profile Details</h3>
              
              <div className="space-y-4">
                <div>
                  <h4 className="text-muted-foreground text-sm mb-1">Age</h4>
                  <p className="text-white">{profile.age} years</p>
                </div>
                
                {profile.measurements && (
                  <>
                    {profile.measurements.height && (
                      <div>
                        <h4 className="text-muted-foreground text-sm mb-1">Height</h4>
                        <p className="text-white">{profile.measurements.height}</p>
                      </div>
                    )}
                    
                    {profile.measurements.bust && (
                      <div>
                        <h4 className="text-muted-foreground text-sm mb-1">Bust</h4>
                        <p className="text-white">{profile.measurements.bust}</p>
                      </div>
                    )}
                    
                    {profile.measurements.dress && (
                      <div>
                        <h4 className="text-muted-foreground text-sm mb-1">Dress Size</h4>
                        <p className="text-white">{profile.measurements.dress}</p>
                      </div>
                    )}
                  </>
                )}
                
                <div>
                  <h4 className="text-muted-foreground text-sm mb-1">Gender</h4>
                  <p className="text-white capitalize">{profile.gender}</p>
                </div>
                
                <div>
                  <h4 className="text-muted-foreground text-sm mb-1">Last Active</h4>
                  <p className="text-white">{new Date(profile.lastActive).toLocaleDateString()}</p>
                </div>
              </div>
            </div>
            
            {(profile.rates.incall || profile.rates.outcall) && (
              <div className="glass-card rounded-xl p-6 mb-6">
                <h3 className="text-lg font-medium text-white mb-4">Rates</h3>
                
                {profile.rates.incall && (
                  <div className="mb-4">
                    <h4 className="text-muted-foreground text-sm mb-2">Incall</h4>
                    <ul className="space-y-2">
                      {profile.rates.incall.hourly && (
                        <li className="flex justify-between">
                          <span className="text-white">1 hour</span>
                          <span className="text-white font-medium">${profile.rates.incall.hourly}</span>
                        </li>
                      )}
                      {profile.rates.incall.twoHours && (
                        <li className="flex justify-between">
                          <span className="text-white">2 hours</span>
                          <span className="text-white font-medium">${profile.rates.incall.twoHours}</span>
                        </li>
                      )}
                      {profile.rates.incall.overnight && (
                        <li className="flex justify-between">
                          <span className="text-white">Overnight</span>
                          <span className="text-white font-medium">${profile.rates.incall.overnight}</span>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                
                {profile.rates.outcall && (
                  <div>
                    <h4 className="text-muted-foreground text-sm mb-2">Outcall</h4>
                    <ul className="space-y-2">
                      {profile.rates.outcall.hourly && (
                        <li className="flex justify-between">
                          <span className="text-white">1 hour</span>
                          <span className="text-white font-medium">${profile.rates.outcall.hourly}</span>
                        </li>
                      )}
                      {profile.rates.outcall.twoHours && (
                        <li className="flex justify-between">
                          <span className="text-white">2 hours</span>
                          <span className="text-white font-medium">${profile.rates.outcall.twoHours}</span>
                        </li>
                      )}
                      {profile.rates.outcall.overnight && (
                        <li className="flex justify-between">
                          <span className="text-white">Overnight</span>
                          <span className="text-white font-medium">${profile.rates.outcall.overnight}</span>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            )}
            
            <div className="glass-card rounded-xl p-6">
              <h3 className="text-lg font-medium text-white mb-4">Availability</h3>
              <div className="flex items-start">
                <Calendar size={18} className="text-muted-foreground mr-3 mt-0.5" />
                <p className="text-white">{formatAvailability(profile.availability)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetail;
