
import React from 'react';
import SeoPageLayout from '@/components/seo/SeoPageLayout';

const StokeOnTrentEscorts = () => {
  return (
    <SeoPageLayout
      title="Stoke-on-Trent Escorts | Professional Escort Services | CAMBIRDS"
      description="Find verified escorts in Stoke-on-Trent. Professional companions available for outcalls and incalls across Stoke-on-Trent and Staffordshire."
      canonicalUrl="https://cambirds.com/escorts/stoke-on-trent"
      pageType="escorts"
      location="Stoke-on-Trent"
      breadcrumbs={[
        { name: 'Home', link: '/' },
        { name: 'Escorts', link: '/search?type=escorts' },
        { name: 'Stoke-on-Trent', link: '/escorts/stoke-on-trent' },
      ]}
      h1="Stoke-on-Trent Escorts"
      h2="Premium Escort Services in Staffordshire"
      introText="Discover professional escort services in Stoke-on-Trent with our curated selection of verified companions. Our platform features elegant and sophisticated escorts offering discreet companionship throughout Stoke-on-Trent and surrounding Staffordshire areas."
      contentSections={[
        {
          title: "Stoke-on-Trent Escort Services",
          content: "Our listed escorts in Stoke-on-Trent provide high-quality companionship for various occasions including dinner dates, social events, and intimate encounters. All profiles are verified to ensure you meet genuine companions who prioritize discretion and client satisfaction."
        },
        {
          title: "Available Escort Services",
          content: "Stoke-on-Trent escorts offer both incall services at comfortable, private locations and outcall services to hotels, homes, and business venues across the city. Whether you're a local resident or visiting on business, our escorts provide memorable experiences tailored to your preferences."
        },
        {
          title: "Areas Covered in Stoke-on-Trent",
          content: "Find escorts available throughout Stoke-on-Trent including Hanley, Burslem, Tunstall, Longton, Fenton, and surrounding villages. Many escorts also provide services to nearby towns in Staffordshire including Newcastle-under-Lyme and Stafford."
        }
      ]}
      relatedLinks={[
        {
          title: "Nearby Escort Services",
          links: [
            { text: "Manchester Escorts", url: "/escorts/manchester" },
            { text: "Birmingham Escorts", url: "/escorts/birmingham" },
            { text: "Newcastle-under-Lyme Escorts", url: "/escorts/newcastle-under-lyme" },
          ]
        },
        {
          title: "Other Services",
          links: [
            { text: "Staffordshire Dominatrix", url: "/dominatrix/staffordshire" },
            { text: "Stoke-on-Trent Massage", url: "/massage/stoke-on-trent" },
            { text: "West Midlands Escorts", url: "/escorts/west-midlands" },
          ]
        }
      ]}
      schemaType="LocalBusiness"
    />
  );
};

export default StokeOnTrentEscorts;
