
import React from 'react';
import SeoPageLayout from '@/components/seo/SeoPageLayout';

const NewcastleUponTyneEscorts = () => {
  return (
    <SeoPageLayout
      title="Newcastle upon Tyne Escorts | Elite Companion Services | CAMBIRDS"
      description="Find verified escorts in Newcastle upon Tyne. Professional companions available for outcalls and incalls throughout Newcastle, Gateshead and Tyne and Wear."
      canonicalUrl="https://cambirds.com/escorts/newcastle-upon-tyne"
      pageType="escorts"
      location="Newcastle upon Tyne"
      breadcrumbs={[
        { name: 'Home', link: '/' },
        { name: 'Escorts', link: '/search?type=escorts' },
        { name: 'Newcastle upon Tyne', link: '/escorts/newcastle-upon-tyne' },
      ]}
      h1="Newcastle upon Tyne Escorts"
      h2="Premium Escort Services in the North East"
      introText="Discover professional escort services in Newcastle upon Tyne with our selection of verified companions. Our platform features sophisticated escorts offering discreet companionship throughout Newcastle, Gateshead, and the surrounding Tyne and Wear region."
      contentSections={[
        {
          title: "Newcastle Escort Services",
          content: "Our Newcastle escorts provide high-quality companionship for various occasions including dinner dates, social events, business functions, and intimate encounters. All profiles are verified to ensure you meet genuine companions who prioritize discretion and client satisfaction."
        },
        {
          title: "Available Escort Services",
          content: "Newcastle escorts offer both incall services at comfortable, private locations and outcall services to hotels, residences, and business venues across the city. Whether you're a local resident or visiting the North East, our escorts provide memorable experiences tailored to your preferences."
        },
        {
          title: "Areas Covered in Newcastle and Tyne and Wear",
          content: "Find escorts available throughout Newcastle upon Tyne including the city centre, Jesmond, Gosforth, Quayside, and surrounding areas. Many escorts also provide services to Gateshead, North Shields, South Shields, and other locations throughout Tyne and Wear."
        }
      ]}
      relatedLinks={[
        {
          title: "Nearby Escort Services",
          links: [
            { text: "Sunderland Escorts", url: "/escorts/sunderland" },
            { text: "Durham Escorts", url: "/escorts/durham" },
            { text: "Middlesbrough Escorts", url: "/escorts/middlesbrough" },
          ]
        },
        {
          title: "Other Services",
          links: [
            { text: "North East Dominatrix", url: "/dominatrix/north-east" },
            { text: "Newcastle Massage", url: "/massage/newcastle" },
            { text: "Northumberland Escorts", url: "/escorts/northumberland" },
          ]
        }
      ]}
      schemaType="LocalBusiness"
    />
  );
};

export default NewcastleUponTyneEscorts;
