
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import SearchEngineSchema from '@/components/seo/SearchEngineSchema';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from '@/components/ui/breadcrumb';

// Sample blog data - in a real app, this would come from a database
const blogPosts = [
  {
    id: 'korean-escorts-london-guide',
    title: 'Ultimate Guide to Korean Escorts in London',
    excerpt: 'Discover everything you need to know about finding authentic Korean escort services in London, including the best areas, what to expect, and how to book.',
    date: '2023-10-20',
    slug: '/blog/korean-escorts-london-guide',
    category: 'Escorts',
    image: '/placeholder.svg'
  },
  {
    id: 'dominatrix-services-explained',
    title: 'Dominatrix Services: A Comprehensive Overview',
    excerpt: 'Learn about different types of dominatrix services available in the UK, from professional domination to specialized BDSM sessions and fetish fulfillment.',
    date: '2023-10-15',
    slug: '/blog/dominatrix-services-explained',
    category: 'Dominatrix',
    image: '/placeholder.svg'
  },
  {
    id: 'nuru-massage-cardiff-guide',
    title: 'Nuru Massage in Cardiff: Everything You Need to Know',
    excerpt: 'Explore the Japanese art of Nuru massage and where to find professional services in Cardiff. Learn about techniques, benefits, and what to expect.',
    date: '2023-10-10',
    slug: '/blog/nuru-massage-cardiff-guide',
    category: 'Massage',
    image: '/placeholder.svg'
  },
  {
    id: 'escort-agencies-uk-guide',
    title: 'Finding Reputable Escort Agencies in the UK',
    excerpt: 'A complete guide to finding and booking with legitimate escort agencies across the UK, including agency vs. independent escorts and what to look for.',
    date: '2023-10-05',
    slug: '/blog/escort-agencies-uk-guide',
    category: 'Escorts',
    image: '/placeholder.svg'
  }
];

const BlogPage = () => {
  return (
    <>
      <Helmet>
        <title>Adult Services Blog | Escorts, Dominatrix & Massage Guides | CAMBIRDS</title>
        <meta name="description" content="Explore informative articles about adult services including escort guides, dominatrix information, and massage service reviews. Get expert advice and insights." />
        <link rel="canonical" href="https://cambirds.com/blog" />
      </Helmet>

      <SearchEngineSchema
        type="WebPage"
        name="Adult Services Blog | CAMBIRDS"
        description="Informative articles about adult services including escort guides, dominatrix information, and massage service reviews."
        url="https://cambirds.com/blog"
        breadcrumbs={[
          { name: "Home", item: "https://cambirds.com/", position: 1 },
          { name: "Blog", item: "https://cambirds.com/blog", position: 2 }
        ]}
      />

      <div className="pt-16 sm:pt-20 md:pt-24 min-h-screen bg-background">
        <div className="container mx-auto px-4 max-w-[1400px]">
          <Breadcrumb className="my-4">
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to="/">Home</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to="/blog">Blog</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>

          <div className="mb-8 sm:mb-12">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-white mb-4">
              Adult Services Blog
            </h1>
            <p className="text-muted-foreground text-lg max-w-3xl">
              Discover informative articles, guides, and resources about adult services including escorts, dominatrix, and massage services across the UK.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
            {blogPosts.map((post) => (
              <Link 
                to={post.slug} 
                key={post.id}
                className="glass-card rounded-lg overflow-hidden hover:border-accent/40 border border-accent/20 transition-all"
              >
                <div className="aspect-video bg-card relative">
                  <img src={post.image} alt={post.title} className="w-full h-full object-cover" />
                  <div className="absolute top-2 right-2 bg-accent/90 text-white text-xs px-2 py-1 rounded">
                    {post.category}
                  </div>
                </div>
                <div className="p-5">
                  <p className="text-muted-foreground text-xs mb-2">
                    {new Date(post.date).toLocaleDateString('en-GB', {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric'
                    })}
                  </p>
                  <h2 className="text-xl font-semibold text-white mb-2 line-clamp-2">
                    {post.title}
                  </h2>
                  <p className="text-sm text-muted-foreground mb-4 line-clamp-3">
                    {post.excerpt}
                  </p>
                  <span className="text-accent text-sm inline-flex items-center">
                    Read More <ArrowRight size={16} className="ml-1" />
                  </span>
                </div>
              </Link>
            ))}
          </div>

          <div className="mb-12">
            <h2 className="text-xl sm:text-2xl font-bold text-white mb-4">
              Popular Topics
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
              <Link to="/blog?category=escorts" className="glass-card p-4 text-center rounded-lg hover:border-accent/40 border border-accent/20 transition-all">
                <h3 className="text-lg font-medium text-white">Escorts</h3>
                <p className="text-sm text-muted-foreground mt-2">Guides & Information</p>
              </Link>
              <Link to="/blog?category=dominatrix" className="glass-card p-4 text-center rounded-lg hover:border-accent/40 border border-accent/20 transition-all">
                <h3 className="text-lg font-medium text-white">Dominatrix</h3>
                <p className="text-sm text-muted-foreground mt-2">BDSM & Mistress Services</p>
              </Link>
              <Link to="/blog?category=massage" className="glass-card p-4 text-center rounded-lg hover:border-accent/40 border border-accent/20 transition-all">
                <h3 className="text-lg font-medium text-white">Massage</h3>
                <p className="text-sm text-muted-foreground mt-2">Adult & Sensual Massage</p>
              </Link>
              <Link to="/blog?category=locations" className="glass-card p-4 text-center rounded-lg hover:border-accent/40 border border-accent/20 transition-all">
                <h3 className="text-lg font-medium text-white">Locations</h3>
                <p className="text-sm text-muted-foreground mt-2">City-specific Guides</p>
              </Link>
            </div>
          </div>

          <div className="prose prose-sm sm:prose prose-invert max-w-none mb-12">
            <h2>Expert Guides to Adult Services</h2>
            <p>
              Our blog provides comprehensive information about adult services across the UK. 
              Whether you're looking for information about escort services, dominatrix professionals, 
              or adult massage offerings, our articles offer valuable insights and practical advice.
            </p>
            <p>
              Written by industry experts, our guides cover topics such as how to find authentic 
              Korean escorts in London, what to expect from a dominatrix session, or where to 
              find legitimate nuru massage services in Cardiff. We prioritize accurate, helpful 
              information to help you make informed decisions.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPage;
