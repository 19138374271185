
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <div className="pt-16 sm:pt-20 md:pt-24 min-h-screen bg-background">
      <div className="container mx-auto px-4 max-w-[1000px]">
        <div className="mb-8 text-center">
          <h1 className="text-2xl sm:text-3xl font-bold text-white mb-4">
            Privacy Policy
          </h1>
          <p className="text-muted-foreground">
            Last Updated: {new Date().toLocaleDateString()}
          </p>
        </div>
        
        <div className="glass-card p-6 sm:p-8 rounded-lg mb-8">
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">1. Introduction</h2>
            <p className="text-muted-foreground mb-4">
              CAMBIRDS ("we", "us", or "our") operates as an affiliate partner for AdultWork. 
              This Privacy Policy aims to give you information on how we collect and process your Personal Data 
              through your use of this site, including any data you may provide when you click through to 
              third-party websites. It will also inform you as to how we look after your Personal Data and tells 
              you about your privacy rights and how the law protects you.
            </p>
            <p className="text-muted-foreground">
              This website is not intended for use by anyone under the age of 18 years, and we do not knowingly 
              collect data relating to children.
            </p>
          </section>
          
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">2. Data We Collect</h2>
            <p className="text-muted-foreground mb-4">
              As an affiliate site, we collect minimal data to provide our service:
            </p>
            <ul className="list-disc pl-6 text-muted-foreground space-y-2 mb-4">
              <li>Technical Data - Internet Protocol (IP) address, your browser type and version, time zone setting and location, 
              browser plug-in types and versions, operating system and platform, and other technology on the devices 
              you use to access this website.</li>
              <li>Usage Data - Information about how you use our website and which links you click on.</li>
            </ul>
            <p className="text-muted-foreground">
              When you click through to AdultWork or other third-party sites, their own privacy policies will apply 
              once you leave our site.
            </p>
          </section>
          
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">3. How We Use Your Data</h2>
            <p className="text-muted-foreground mb-4">
              We use the data we collect for the following purposes:
            </p>
            <ul className="list-disc pl-6 text-muted-foreground space-y-2">
              <li>To administer and protect our website (including troubleshooting, data analysis, testing, 
              system maintenance, support, reporting and hosting of data)</li>
              <li>To measure and analyze the effectiveness of our affiliate links</li>
              <li>To improve our website and user experience</li>
              <li>To comply with legal obligations</li>
            </ul>
          </section>
          
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">4. Cookies and Tracking</h2>
            <p className="text-muted-foreground mb-4">
              We use cookies and similar tracking technologies to track the activity on our service and store certain 
              information. These technologies are used to remember your preferences, understand how you use our site, 
              and help us improve our service.
            </p>
            <p className="text-muted-foreground">
              You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, 
              if you do not accept cookies, you may not be able to use some portions of our service.
            </p>
          </section>
          
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">5. Third-Party Links</h2>
            <p className="text-muted-foreground mb-4">
              Our website contains links to third-party websites, primarily AdultWork. These external sites have 
              their own privacy policies, and we do not accept any responsibility or liability for these policies 
              or sites. Please check these policies before you submit any personal data to these websites.
            </p>
            <p className="text-muted-foreground">
              When you click on affiliate links to AdultWork, you will be subject to AdultWork's privacy policy, 
              which can be found on their website.
            </p>
          </section>
          
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">6. Data Security</h2>
            <p className="text-muted-foreground">
              We have implemented appropriate security measures to protect your data from accidental loss, use, 
              access, alteration, and disclosure. We limit access to your data to those employees, agents, and 
              third parties who have a business need to know.
            </p>
          </section>
          
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">7. Your Legal Rights</h2>
            <p className="text-muted-foreground mb-4">
              Under certain circumstances, you have rights under data protection laws in relation to your personal data, 
              including:
            </p>
            <ul className="list-disc pl-6 text-muted-foreground space-y-2">
              <li>The right to access your personal data</li>
              <li>The right to correction of your personal data</li>
              <li>The right to erasure of your personal data</li>
              <li>The right to restrict processing of your personal data</li>
              <li>The right to data portability</li>
              <li>The right to object to processing of your personal data</li>
            </ul>
          </section>
          
          <section>
            <h2 className="text-xl font-semibold text-white mb-4">8. Contact Us</h2>
            <p className="text-muted-foreground">
              If you have any questions about this Privacy Policy, please contact us at:
              <Link to="/contact" className="text-primary hover:underline ml-1">
                privacy@cambirds.com
              </Link>
            </p>
          </section>
        </div>
        
        <div className="text-center mb-8">
          <p className="text-muted-foreground text-sm">
            © {currentYear} CAMBIRDS. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
