
import { useLocation, Link } from "react-router-dom";
import { useEffect } from "react";
import { Home, Search } from "lucide-react";

const NotFound = () => {
  const location = useLocation();

  useEffect(() => {
    console.error(
      "404 Error: User attempted to access non-existent route:",
      location.pathname
    );
  }, [location.pathname]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-background pt-16">
      <div className="glass-card rounded-xl p-10 text-center max-w-lg mx-auto animate-fade-in">
        <h1 className="text-6xl font-bold text-primary mb-4">404</h1>
        <h2 className="text-3xl font-medium text-white mb-6">Page Not Found</h2>
        <p className="text-lg text-muted-foreground mb-8">
          The page you're looking for doesn't exist or has been moved.
        </p>
        <div className="flex flex-col sm:flex-row justify-center gap-4">
          <Link 
            to="/" 
            className="bg-primary text-white px-6 py-3 rounded-full flex items-center justify-center shadow-lg hover:shadow-primary/20 transition-all"
          >
            <Home size={18} className="mr-2" />
            Return Home
          </Link>
          <Link 
            to="/search" 
            className="bg-secondary text-white px-6 py-3 rounded-full flex items-center justify-center shadow-lg hover:bg-secondary/90 transition-colors"
          >
            <Search size={18} className="mr-2" />
            Search Profiles
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
