
import React from 'react';
import SeoPageLayout from '@/components/seo/SeoPageLayout';

const DoncasterEscorts = () => {
  return (
    <SeoPageLayout
      title="Doncaster Escorts | Professional Escort Agency | CAMBIRDS"
      description="Find verified escorts in Doncaster. Professional and discreet escort services available for incalls and outcalls throughout Doncaster and South Yorkshire."
      canonicalUrl="https://cambirds.com/escorts/doncaster"
      pageType="escorts"
      location="Doncaster"
      breadcrumbs={[
        { name: 'Home', link: '/' },
        { name: 'Escorts', link: '/search?type=escorts' },
        { name: 'Doncaster', link: '/escorts/doncaster' },
      ]}
      h1="Doncaster Escorts"
      h2="Elite Escort Services in Doncaster"
      introText="Connect with professional escorts in Doncaster offering premium companionship services. Our verified listings feature sophisticated escorts available for both incall and outcall services throughout Doncaster and the wider South Yorkshire area."
      contentSections={[
        {
          title: "Doncaster Escort Agency Services",
          content: "Our Doncaster escorts provide high-quality companionship for various occasions from dinner dates and social events to more intimate encounters. Each profile is carefully verified to ensure an authentic experience with professional companions who prioritize discretion."
        },
        {
          title: "Escort Services Available",
          content: "Doncaster escorts offer flexible booking options including incall services at comfortable, private locations and outcall services to hotels, residences, and business venues across the region. Whether you're a local resident or visiting Doncaster, our escorts create memorable experiences tailored to your preferences."
        },
        {
          title: "Areas Covered in Doncaster",
          content: "Find escorts available throughout Doncaster including the town center, Lakeside, Balby, Bessacarr, and surrounding villages. Many escorts also provide services to nearby towns in South Yorkshire including services near Doncaster Sheffield Airport."
        }
      ]}
      relatedLinks={[
        {
          title: "Nearby Escort Services",
          links: [
            { text: "Sheffield Escorts", url: "/escorts/sheffield" },
            { text: "Rotherham Escorts", url: "/escorts/rotherham" },
            { text: "Leeds Escorts", url: "/escorts/leeds" },
          ]
        },
        {
          title: "Other Services",
          links: [
            { text: "Yorkshire Dominatrix", url: "/dominatrix/yorkshire" },
            { text: "Doncaster Massage", url: "/massage/doncaster" },
            { text: "South Yorkshire Escorts", url: "/escorts/south-yorkshire" },
          ]
        }
      ]}
      schemaType="LocalBusiness"
    />
  );
};

export default DoncasterEscorts;
